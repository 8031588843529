import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll'; // For smooth scrolling
import { Link as Link2 } from 'react-router-dom'; // For routing to Sponsor page
import './Header.css';
import './Header.css';
import AarohiLogo from "../assets/aarohiLogo.png";
import { FaBars, FaTimes } from 'react-icons/fa'; // Correct import for react-icons

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu visibility

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <a className='halloween-animation' href="#">
      <div className="navbar-logo">
        <img src={AarohiLogo} alt="Aarohi Logo" height={40} />
      </div>
      </a>
      <div className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
        {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </div>
      <nav className={`navbar-links ${menuOpen ? 'active' : ''}`}>
        <Link to="home" smooth={true} duration={500} onClick={() => setMenuOpen(false)}>
          Home
        </Link>
        <Link to="about" smooth={true} duration={500} onClick={() => setMenuOpen(false)}>
          About
        </Link>
        <Link to="events" smooth={true} duration={500} onClick={() => setMenuOpen(false)}>
          Events
        </Link>
        <Link to="artists" smooth={true} duration={500} onClick={() => setMenuOpen(false)}>
          Artists
        </Link>
        <Link to="contact" smooth={true} duration={500} onClick={() => setMenuOpen(false)}>
          Contact
        </Link>
        <Link2 to="/sponsor" smooth={true} duration={500} onClick={() => setMenuOpen(false)}>
          Sponsors
        </Link2>
      </nav>
    </header>
  );
}

export default Header;
