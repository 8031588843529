import React, { useEffect } from 'react';
import './About.css'; // Ensure this CSS file is created
import People from "../assets/people1.png";
import Legacy from "../assets/legacy1.png";
import Events from "../assets/mentors1.png";
import Prices from "../assets/prices1.png";
import Firepot from "./Firepot";

const About = () => {
  const animateNumber = (element, start, end, duration) => {
    let range = end - start;
    let current = start;
    let increment = range / (duration / 10);
    let stepTime = 10;
    let timer = setInterval(() => {
      current += increment;
      if (current >= end) {
        current = end;
        clearInterval(timer);
      }
      element.textContent = Math.round(current);
    }, stepTime);
  };

  useEffect(() => {
    const entriesNumber = document.getElementById('entries-number');
    const entriesNumber2 = document.getElementById('entries-number2');
    const entriesNumber3 = document.getElementById('entries-number3');
    const entriesNumber4 = document.getElementById('entries-number4');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateNumber(entriesNumber, 1, 30000, 1500);
            animateNumber(entriesNumber2, 1, 600000, 1500);
            animateNumber(entriesNumber3, 1, 13, 1500);
            animateNumber(entriesNumber4, 1, 36, 1500);
            observer.unobserve(entry.target); // Stop observing once animated
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observing the first element (others will trigger together)
    if (entriesNumber) {
      observer.observe(entriesNumber);
    }

    // Cleanup observer on component unmount
    return () => {
      if (entriesNumber) observer.unobserve(entriesNumber);
    };
  }, []);

  return (
    <div className="about-container">
      <h1 className="about-flicker">About Aarohi</h1>
      <div className="sections-container">
        <div className="left-section"></div>
        <div className="right-section">
          <Firepot />
        </div>
      </div>
      <div className="bottom-section">
        <div className="box">
          <img src={People} alt="Icon 1" width="80" height="80" />
          <p className="main-info">
            <span id="entries-number">1000</span>+
          </p>
          <p className="info">Footfall</p>
        </div>
        <div className="box">
          <img src={Prices} alt="Icon 2" width="80" height="80" />
          <p className="main-info">
            <span id="entries-number2">1000</span>+
          </p>
          <p className="info">Prices Worth</p>
        </div>
        <div className="box">
          <img src={Events} alt="Icon 3" width="80" height="80" />
          <p className="main-info">
            <span id="entries-number3">70</span>+
          </p>
          <p className="info">Events</p>
        </div>
        <div className="box">
          <img src={Legacy} alt="Icon 4" width="80" height="80" />
          <p className="main-info">
            <span id="entries-number4">0</span>th
          </p>
          <p className="info">Legacy</p>
        </div>
      </div>

      {/* Gallery Heading */}
      <h2 className="about-flicker">Flashbacks</h2>
      
      {/* Infinite Gallery Section */}
      <div className="gallery-container">
        <div className="gallery">
          {Array.from({ length: 1 }).map((_, index) => (
            <img key={index} src={require("../assets/gallery/1.jpg")} alt={`Gallery Item ${index + 1}`} />
          ))}
          {Array.from({ length: 1 }).map((_, index) => (
            <img key={index} src={require("../assets/gallery/2.JPG")} alt={`Gallery Item ${index + 1}`} />
          ))}
          {Array.from({ length: 1 }).map((_, index) => (
            <img key={index} src={require("../assets/gallery/WhatsApp Image 2024-10-21 at 19.47.26_3312834f.jpg")} alt={`Gallery Item ${index + 1}`} />
          ))}
          {Array.from({ length: 1 }).map((_, index) => (
            <img key={index} src={require("../assets/gallery/WhatsApp Image 2024-10-21 at 19.47.27_1e8a9a21.jpg")} alt={`Gallery Item ${index + 1}`} />
          ))}
          {Array.from({ length: 1 }).map((_, index) => (
            <img key={index} src={require("../assets/gallery/WhatsApp Image 2024-10-21 at 19.47.27_7ec6566d.jpg")} alt={`Gallery Item ${index + 1}`} />
          ))}
          {Array.from({ length: 1 }).map((_, index) => (
            <img key={index} src={require("../assets/gallery/WhatsApp Image 2024-10-21 at 19.47.28_b81a7597.jpg")} alt={`Gallery Item ${index + 1}`} />
          ))}
          {Array.from({ length: 1 }).map((_, index) => (
            <img key={index} src={require("../assets/gallery/WhatsApp Image 2024-10-21 at 19.47.29_41f8d8ca.jpg")} alt={`Gallery Item ${index + 1}`} />
          ))}
          {Array.from({ length: 1 }).map((_, index) => (
            <img key={index} src={require("../assets/gallery/6.JPG")} alt={`Gallery Item ${index + 1}`} />
          ))}
          {Array.from({ length: 1 }).map((_, index) => (
            <img key={index} src={require("../assets/gallery/WhatsApp Image 2024-10-21 at 19.47.27_7ec6566d.jpg")} alt={`Gallery Item ${index + 1}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
