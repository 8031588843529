import React, { useEffect, useState } from 'react';
import './Events.css';
import Card from './Card';
import accession from '../assets/ascension.jpeg';
import purplehaze from '../assets/purpleHaze.jpeg';
import swarmanzar from '../assets/swarmanzar.jpeg';
import hunkaar from '../assets/hunkar.jpeg';
import artConsoza from '../assets/artconsoza.jpg';
import bigFight from '../assets/bigfight.jpg';
import madhurAntakshari from '../assets/madhur_antakshari.jpg';
import aaLaDance from '../assets/aladance.jpg';
import rhythmDivine from '../assets/rhythmdivine.jpg';
import cynosure from '../assets/cynosure.jpg';
import abhivyakti from '../assets/abhivyakti.jpg';

function Events() {
  const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
          // Check if the scroll position is greater than 600px
          if (window.scrollY > 1500) {
            setIsActive(true);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup the event listener when component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <section id="events" className="events-section ">
      <h2 className='events-flicker'>Our Spooky Events</h2>
      <div>
      {/* <ParticlesComponent/> */}
      </div>
      <div className={`event-cards slide-in-right ${isActive ? 'active' : ''}`}>
        <div className='card'>
        <Card 
          title="Ascension" 
          text="An exciting online gaming competition featuring popular titles like Valorant, BGMI, and Real Cricket 24. Gamers compete individually or in teams, bringing the thrill of eSports to Aarohi’s dynamic event lineup." 
          img={accession}
          date ="16th and 17th November . 2024"  
          prize = "Prizes worth 75k"
          contacts = "Santhosh BTS : +91 93917 96349 Vaishnavi Macharla: +91 93929 73227 Hetal Pawar : +91 5622 36679 "
          />
          <a href='https://forms.gle/N1zgzjQU6ZHG4sEi7' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        <div className='card'>
        <Card 
          title="Purple Haze" 
          text="A rock music extravaganza that electrifies the stage with powerful performances from bands. This high-energy event features intense guitar riffs, booming drums, and captivating vocals, creating an unforgettable atmosphere for rock music lovers." 
          img={purplehaze}
          date ="16th November . 2024"  
          prize = "Prizes worth 50k"
          contacts = "Harsh Rajak : +91 78752 17395 Anisha Gotmare : +91 70585 39042 "
          />
          <a href='https://forms.gle/UsfLmRtFkLbpY4CS7' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        <div className='card'>
        <Card 
          title="Swarmanzar" 
          text="Swarmanzar is our college's premier singing competition, offering a stage for musical talents to shine. Participants will captivate the audience with diverse genres and styles, competing for top honors in this lively event." 
          img={swarmanzar}
          date ="16th and 17th November . 2024"  
          prize = "Prizes worth 75k"
          contacts = "Santhosh BTS : +91 93917 96349 Vaishnavi Macharla: +91 93929 73227 Hetal Pawar : +91 5622 36679 "
          />
          <a href='https://forms.gle/VF7CoHp9ykpR9tht9' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        <div className='card'>
        <Card 
          title="Hunkaar" 
          text="A powerful street play event that addresses pressing social issues. Teams perform bold, thought-provoking narratives in public spaces, blending activism with theatre to engage audiences and spark meaningful conversations." 
          img={hunkaar}
          date ="16th November . 2024"  
          prize = "Prizes worth 40k"
          contacts = "Aditya Khanna : +91 91748 85947 Abdur Rehmann Sheikh: +91 70582 23635  "
          />
          <a href='https://forms.gle/kRLCEPRhVzX7qK6Z9' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        {/* abhivyakti */}
        <div className='card'>
        <Card 
          title="Abhivyakti" 
          text="A stage play competition where teams perform scripted or original dramas. Judged on storytelling, acting, and direction, this event celebrates the theatrical arts, transporting audiences into vibrant, emotional narratives." 
          img={abhivyakti}
          date ="17th November . 2024"  
          prize = "Prizes worth 20k"
          contacts = "Shahil Ahmed: +91 84518 01222 Vatsa Khatri : +91 63588 14344"
          />
          <a href='https://forms.gle/8yg7Mgbmou13hrM59' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        {/* art consoza */}
        <div className='card'>
        <Card 
          title="Art Consoza" 
          text="An artistic showcase that celebrates creativity through painting, sketching, and installations. Participants explore various styles and mediums, pushing their artistic boundaries while sharing their unique vision with the Aarohi community." 
          img={artConsoza}
          date ="16th , 17th November , 2024"  
          prize = "Win exciting prizes"
          contacts = "Vedang Lokhande : +91 83290 31893 Asavari : +91 73879 88247"
          />
          <a href='https://forms.gle/yQpVabeN4WD583YDA' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        {/* cynosure */}
        <div className='card'>
        <Card 
          title="Cynosure" 
          text="A personality contest testing participants’ wit, presence of mind, and creativity through rounds like Q&A, ramp walk, and extempore speech. Charisma and confidence take center stage in this engaging and dynamic event." 
          img={cynosure}
          date ="17th November , 2024"  
          prize = "Win exciting prizes"
          contacts = "Chetan: +91 75691 70151 Srikar : +91 81791 67795"
          />
          <a href='https://forms.gle/EqheiFyJYATqpDjf8' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        {/* Big fight */}
        <div className='card'>
        <Card 
          title="Big fight" 
          text="A heated debate event where participants argue on controversial, thought-provoking topics. Teams present well-reasoned arguments and counterpoints in a competitive format that promotes critical thinking and effective communication." 
          img={bigFight}
          date ="17th November, 2024"  
          prize = "Prizepool : Goodies / exciting prizes"
          contacts = "Chetan: +91 75691 70151 Srikar : +91 81791 67795"
          />
          <a href='https://forms.gle/wC5PmoXA5YcEezYL9' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        {/* madhur antakshari */}
        <div className='card'>
        <Card 
          title="Madhur Antakshari" 
          text=" A lively Bollywood musical quiz where participants engage in fun song challenges. Teams race against the clock, identifying and completing songs, creating a nostalgic, energetic atmosphere of musical excitement." 
          img={madhurAntakshari}
          date ="16th November, 2024"  
          prize = "Prizepool : Goodies / exciting prizes"
          contacts = "Sarvan: 7842612535    KS Uday Bhaskar : +91 91824 76356"
          />
          <a href='https://forms.gle/JrrA3skWtYtP7Axp9' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        {/* Aa la dance */}
        <div className='card'>
        <Card 
          title="Aa la dance" 
          text="A high-energy dance competition showcasing diverse styles, from classical to contemporary. Teams dazzle the audience with their choreography, synchronization, and stage presence, making it an electrifying event full of rhythm and movement." 
          img={aaLaDance}
          date ="17th November . 2024"  
          prize = "Prize pool : 40k"
          contacts = "Janhvi Singh : +91 70238 96764   Ankit Kumar : +91 62063 12522"
          />
          <a href='https://forms.gle/P8NPmAEbrAkEjkMn7' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        {/* rhythm divine */}
        <div className='card'>
          <Card 
          title="Rhythm Divine" 
          text="An instrumental music event where talented musicians perform on a variety of instruments, from classical to experimental. The event captivates the audience with beautiful melodies, harmonies, and powerful performances" 
          img={rhythmDivine}
          date ="17th November, 2024"  
          prize = "Prizes worth 10k"
          contacts = "Santhosh BTS : +91 93917 96349 Vaishnavi Macharla: +91 93929 73227 Hetal Pawar : +91 5622 36679 "
          />
          <a href='https://forms.gle/H7C34Z3scoMHHaER6' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
        <div className='card'>
        <Card 
          title="Olympia" 
          text="An instrumental music event where talented musicians perform on a variety of instruments, from classical to experimental. The event captivates the audience with beautiful melodies, harmonies, and powerful performances" 
          img={swarmanzar}
          date ="17th November, 2024"  
          prize = "Prizes worth 10k"
          contacts = "Santhosh BTS : +91 93917 96349 Vaishnavi Macharla: +91 93929 73227 Hetal Pawar : +91 5622 36679 "
          />
          <a href='#' target='_blank' rel='noreferrer'>
          <div className='buttons'>
              <h3>Register</h3>
          </div>
          </a>
        </div>
          
        </div>
      
      
    </section>
  );
}

export default Events;