import React from 'react';
import './Artists.css';

const Artists = () => {
  return (
    <div className="artists-section">
      <h2 className="sponsors-heading">Our Spooky Artists</h2>
      <div className="cards-container">
        <div className="artist-card1">
          <div className="curtain"></div>
          <img src={require('../assets/nikhil.jpg')} alt="Artist" className="artist-image" />
        </div>
        <div className="artist-card2  ">
          <div className="curtain"></div>
          <img src={require('../assets/vivek.png')} alt="Artist" className="artist-image2" />
        </div>
      </div>
    </div>
  );
};

export default Artists;