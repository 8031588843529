import React from 'react';
import { motion } from 'framer-motion';
import cloudImage from '../assets/cloud.png';
import cloudImage2 from '../assets/cloud.png';
import moonImage from '../assets/moon2.png';
import grass1Image from '../assets/grass.png';
import grass2Image from '../assets/grass.png';
import handImage from '../assets/handnew.png';
import handhole from '../assets/handhole.png';
import './Home.css';

function Home() {
  return (
    <div className="home-section">
      {/* Cloud Animation */}
      <motion.div
        className="cloud"
        initial={{ y: '-100vh' }} 
        animate={{ y: 0 }} 
        transition={{ type: 'spring', stiffness: 60, delay: 0.5 }} 
      >
        <motion.img
          src={cloudImage}
          alt="Cloud"
          animate={{
            x: ['0vw', '-100vw'], // Moves cloud from its current position to the left
          }}
          transition={{
            duration: 40, // Speed of horizontal movement
            repeat: Infinity, // Infinite loop
            ease: 'linear', // Smooth motion
            delay: 1.5, // Delay after it finishes coming down
          }}
        />
      </motion.div>

      <motion.div
        className="cloud2"
        initial={{ y: '-100vh' }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 60, delay: 0.5 }}
      >
        <motion.img
          src={cloudImage2}
          alt="Cloud2"
          animate={{
            x: ['0vw', '-100vw'], // Moves cloud2 from right to left
          }}
          transition={{
            duration: 40,
            repeat: Infinity,
            ease: 'linear',
            delay: 1.5, // Delay for the horizontal movement
          }}
        />
      </motion.div>

      <motion.div
        className="cloud3"
        initial={{ y: '-100vh' }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 60, delay: 0.5 }}
      >
        <motion.img
          src={cloudImage2}
          alt="Cloud2"
          animate={{
            x: ['0vw', '-100vw'], // Moves cloud2 from right to left
          }}
          transition={{
            duration: 40,
            repeat: Infinity,
            ease: 'linear',
            delay: 1.5, // Delay for the horizontal movement
          }}
        />
      </motion.div>

      {/* Moon Animation */}
      <motion.div
        className="moonhome"
        initial={{ y: '100vh' }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 60, delay: 1 }}
      >
        <img src={moonImage} style={{ width: '350px', height: 'auto' }} alt="Moon" />
      </motion.div>

      {/* First Grass Layer Animation */}
      <motion.div
        className="grass-1"
        initial={{ y: '100vh' }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 60, delay: 1.5 }}
      />
      <motion.div
        className="grass-2"
        initial={{ y: '100vh' }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 60, delay: 2 }}
      />

      <motion.div
        className="handhole"
        initial={{ y: '100vh' }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 60, delay: 2.7 }}
      >
        <img src={handhole} alt="handhole" />
      </motion.div>

      {/* Hand and Final Grass Layer Animation */}
      <motion.div
        className="hand"
        initial={{ y: '100vh' }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 60, delay: 3.4 }}
      >
        <img src={handImage} alt="Hand" style={{ width: '650px', height: 'auto' }} />
      </motion.div>
    </div>
  );
}

export default Home;
