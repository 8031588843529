import React, { useEffect } from 'react';
import './Firepot.css';

function Firepot() {
  useEffect(() => {
    var canvas = document.getElementById('canvas');
    if (!canvas) return;

    var context = canvas.getContext('2d');
    var W = canvas.width = window.innerWidth/2;
    var H = canvas.height = window.innerHeight/2;
    var generatorStock = [];

    generatorStock.push(new particleGenerator(2, 3, 30, 30));

    function randomInt(min, max) {
      return Math.floor(min + Math.random() * (max - min + 1));
    }

    function particle(vx, vy, size) {
      this.radius = randomInt(0, size);
      this.x = W / 2;
      this.y = H / 2;
      this.alpha = 1;
      this.vx = randomInt(-vx, vx);
      if (Math.random() < 0.1) {
        this.vy = randomInt(-vy, -vy - 3);
      } else {
        this.vy = randomInt(0, -vy);
      }
      this.lifetime = 100;
    }
    particle.prototype.update = function () {
      this.lifetime -= 2;
      this.x += this.vx;
      this.y += this.vy;

      if (this.lifetime < 20) {
        this.radius -= 2;
      }

      context.beginPath();
      context.arc(this.x, this.y, this.radius, Math.PI *2, false);
      context.fillStyle = 'rgba(255,255,255,0.5)';
      context.strokeStyle = '#ffc422';
      context.lineWidth = 10;
      context.fill();
      context.stroke();
      context.closePath();
    };

    function particleGenerator(vx, vy, size, maxParticles) {
      this.size = size;
      this.vx = vx;
      this.vy = vy;
      this.maxParticles = maxParticles;
      this.particles = [];
    }
    var freq = 0.5;
    particleGenerator.prototype.animate = function () {
      if (this.particles.length < this.maxParticles && Math.random() < freq) {
        this.particles.push(new particle(this.vx, this.vy, this.size));
        if (this.particles.length === this.maxParticles / 2) {
          freq = 0.1;
        }
      }
      for (var i = 0; i < this.particles.length; i++) {
        var p = this.particles[i];
        p.update();
        if (p.radius < 10) {
          this.particles[i] = new particle(this.vx, this.vy, this.size);
        }
      }
    };

    function update() {
      context.clearRect(0, 0, W, H);

      for (var i = 0; i < generatorStock.length; i++) {
        generatorStock[i].animate();
      }
      window.requestAnimationFrame(update);
    }

    update();

    function handleResize() {
      W = window.innerWidth/2;
      H = window.innerHeight/2;
      canvas.width = W;
      canvas.height = H;
    }

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the resize event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array to ensure effect runs once

  return (
    <section >
      <div>
        <div className="gooeys center">
          <canvas style={{ position: 'relative' }} id="canvas"></canvas>
        </div>

        <div className="glow center"></div>
        <div className="shadow center"></div>
        <div className="pot-top center"></div>
        <div className="pot center"></div>

        <svg>
          <defs>
            <filter id="filter">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 17 -10"
                result="filter"
              />
              <feBlend />
            </filter>
          </defs>
        </svg>
      </div>
    </section>
  );
}

export default Firepot;
