import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import './Card.css';

function Card(props) {
  const { img, title, text, date, prize , contacts } = props;
  const style = { backgroundImage: `url(${img})` };
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  return (
    <div
      className={`spooky-house ${flipped ? 'flipped' : ''}`}
      onClick={handleFlip}
    >
      <div className="card-inner">
        {/* Front Side */}
        <div className="card-front" style={style}>
          <div className="frame">
            <div className="rain">
              <div className="dropOne"></div>
              <div className="dropTwo"></div>
              <div className="dropThree"></div>
              <div className="dropFour"></div>
              <div className="dropFive"></div>
            </div>
          </div>
        </div>

        {/* Back Side */}
        <div className="card-back">
        <div className='card-content'>
          <h2>{title || 'Back Title'}</h2>
          <p>{text || 'Additional information goes here.'}</p>
          <p>{date || 'Additional information goes here.'}</p>
          <p>{prize || 'Additional information goes here.'}</p>
          <p>{contacts || 'Additional information goes here.'}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

// Adding prop types for validation
Card.propTypes = {
  img: PropTypes.string.isRequired, // Image URL is required
  title: PropTypes.string,
  text: PropTypes.string,
  backTitle: PropTypes.string,
  backContent: PropTypes.string,
};

export default Card;