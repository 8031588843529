import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Sponsor.css';

import ORALQELICA from '../assets/ORALQELICA.png';
import FESTJRNY from '../assets/FESTJRNY.png';
import MOBILEVIVO from '../assets/MOBILEVIVO.png';
import TRIPTRIP247 from '../assets/TRIPTRIP247.png';
import SKINNUTRI from '../assets/SKINNUTRI.png';
import VISOPTPLN from '../assets/VISOPTPLN.png';
import TALENTMI from '../assets/TALENTMI.png';
import RIDERE from '../assets/RIDERE.png';
import ASSOCIATECOTITLE from '../assets/ASSOCIATECOTITLE.png';
import FITBLU from '../assets/FITBLU.png';
import STUDYABROADSIUK from '../assets/STUDYABROADSIUK.png';
import POWEREDBYHG from '../assets/POWEREDBYHG.png';
import DELZOMATO from '../assets/DELZOMATO.png';
import TRAVELCONFIRMTKT from '../assets/TRAVELCONFIRMTKT.png';
import COTITLESBI from '../assets/COTITLESBI.png';
import COPRESENTEDBYNBCC from '../assets/COPRESENTEDBYNBCC.png';
import TITLECAMPA from '../assets/TITLECAMPA.png';

const sponsors = [
  { id: 2, image: ASSOCIATECOTITLE },
  { id: 3, image: COTITLESBI },
  { id: 4, image: COPRESENTEDBYNBCC },
  { id: 5, image: POWEREDBYHG },
  { id: 6, image: VISOPTPLN },
  { id: 7, image: TALENTMI },
  { id: 8, image: RIDERE },
  { id: 9, image: FESTJRNY },
  { id: 10, image: FITBLU },
  { id: 11, image: STUDYABROADSIUK },
  { id: 12, image: SKINNUTRI },
  { id: 13, image: DELZOMATO },
  { id: 14, image: TRAVELCONFIRMTKT },
  { id: 15, image: MOBILEVIVO },
  { id: 16, image: TRIPTRIP247 },
  { id: 17, image: ORALQELICA },
];

const Sponsor = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="sponsors-section">
      <button onClick={handleGoBack} className="go-back-button">Go Back</button>
      <h2 className="sponsors-heading">Our Spooky Sponsors</h2>

      {/* CAMPA Image Section */}
      <div className="campa-image-container">
        <img src={TITLECAMPA} alt="Campa Sponsor" className="campa-image" />
      </div>

      <div className="sponsors-gallery">
        {sponsors.map((sponsor) => (
          <div key={sponsor.id} className="sponsor-item">
            <img src={sponsor.image} alt={`Sponsor ${sponsor.id}`} className="sponsor-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sponsor;
