// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';  // Import useLocation
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Events from './components/Events';
import Artists from './components/Artists';
import Contact from './components/Contact';
import Sponsor from './components/Sponsor';  // Import the Sponsor page
import FAQs from './components/FAQs';
import './index.css';

const App = () => {
  const location = useLocation();
  const isSponsorPage = location.pathname === '/sponsor';

  return (
    <>
      {/* Render Header only if it's not the Sponsor page */}
      {!isSponsorPage && <Header />}
      
      <Routes>
        {/* Route for the Sponsor page */}
        <Route path="/sponsor" element={<Sponsor />} />

        {/* Default route for Home (this will be the main page) */}
        <Route path="/" element={
          <div className="background-constant">
            <div id="home">
              <Home />
            </div>
            <div id="about">
              <About />
            </div>
            <div id="events">
              <Events />
            </div>
            <div id="artists">
              <Artists />
            </div>
            <div id="faqs">
              <FAQs />
            </div>
            <div id="contact">
              <Contact />
            </div>
          </div>
        } />
      </Routes>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
