import React from 'react';
import { Link } from 'react-scroll'; // Import Link from react-scroll
import './Contact.css';
import aarohiLogo from "../assets/aarohiLogo.png";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import youtube from "../assets/youtube.png";

function Contact() {
  return (
    <section id="contact" className="contact-section">
      <hr className='yellow-line'/>
      <div id="contacts-background" className="contacts-section spooky-border">
        <footer className="contacts-background">
          <div className="contacts-logo">
            <img src={aarohiLogo} alt="axis logo" />
            <div className="contacts-info">
              Aarohi is the annual cultural festival of Visvesvaraya National Institute of Technology (VNIT), Nagpur. It's one of the largest curtural fests in central India, attracting students from various colleges across the country. The event typically features a wide range of activities, including cultural competitions, workshops, guest lectures, concerts, and paper presentations.
            </div>
            {/* Follow Us Section moved here */}
            <div className="follow-us">
              <div className="follow-us-heading">FOLLOW US</div>
              <div className="social-icons">
                <a href="https://www.instagram.com/aarohi_vnitnagpur/" target="_blank" rel="noopener noreferrer">
                  <img src={instagram} alt="Instagram" />
                </a>
                <a href="https://www.facebook.com/AarohiWorld/" target="_blank" rel="noopener noreferrer">
                  <img src={facebook} alt="Facebook" />
                </a>
                <a href="https://www.youtube.com/@VNITAarohi" target="_blank" rel="noopener noreferrer">
                  <img src={youtube} alt="YouTube" />
                </a>
              </div>
            </div>
          </div>
          <div className="contacts-writing">
            <div className="quick-links">
              <div className="quick-links-heading">QUICK LINKS</div>
              <div className="quick-links-content">
                <div><Link to="home" smooth={true} duration={500} >
          Home
        </Link></div>
                <div><Link to="about" smooth={true} duration={500} >
          About
        </Link></div>
                <div> <Link to="events" smooth={true} duration={500} >
          Events
        </Link></div>
                <div><Link to="artists" smooth={true} duration={500} >
          Artists
        </Link></div>
                <div><Link to="contact" smooth={true} duration={500} >
          Contact
        </Link></div>
                
        
       
        
        
              </div>
            </div>

            <div className="reach-out">
              <div className="reach-out-heading">REACH OUT</div>
              <div className="reach-out-address">
                Visvesvaraya National Institute of Technology Nagpur<br />
                South Ambazari Road, Nagpur, Maharashtra.
              </div>
            </div>

            <div className="contact">
              <div className="contact-heading">CONTACT</div>
              <div className="contact-numbers">
                Harsh  : +91 8446552403<br /><br />
                Hardik  : +91 9867239393<br /><br />
                Suhani  : +91 9414634702<br /><br />
                Naman  : +91 9907120656<br /><br />
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className='spooky-border'> Copyright @ Aarohi 2024 </div>
    </section>
  );
}

export default Contact;
