import React, { useState } from 'react';
import './FAQs.css';

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    { question: "What is Aarohi?", answer: "Aarohi is the annual cultural festival of Visvesvaraya National Institute of Technology (VNIT), Nagpur. It's one of the largest curtural fests in central India, attracting students from various colleges across the country!" },
    { question: "When is Aarohi?", answer: "Aarohi takes place on 16-17th Nov. Stay tuned for details!" },
    { question: "How can I participate?", answer: "Register on the Aarohi site to join our events!" },
    { question: "Is there an entry fee?", answer: "Passes are available for purchase. For more details or to buy a pass, please contact us directly!" },
  ];

  return (
    <div className="faq-section">
      <h2 className="faq-heading">FAQs</h2>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <h3>{faq.question}</h3>
              <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;